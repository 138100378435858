var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('SearchPanel',{attrs:{"search-params":_vm.searchParamsObject,"showMultipleSelect":true,"showMultipleInput":true},on:{"update:searchParams":function (val, key, index) { _vm.searchParamsObject[index].value = val },"button-event":_vm.searhPanelButtonEvent}}),_c('b-card',[_c('MainActionPanel',{attrs:{"buttonLeftOptions":_vm.buttonLeftOptions,"buttonRightOptions":_vm.buttonRightOptions},on:{"main-action":_vm.mainActionEvent}}),_c('vxe-table',{ref:"xTable",attrs:{"border":"","auto-resize":true,"data":_vm.tableData,"highlight-current-row":"","cell-class-name":_vm.cellClassName,"loading":_vm.loading,"scroll-x":{enabled: true},"expand-config":{lazy: true, loadMethod: _vm.getItemMethod, visibleMethod: _vm.expandVisibleMethod },"scroll-y":{enabled: true}}},[_c('vxe-table-column',{attrs:{"type":"expand","field":"id","title":"ID","width":"80","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('span',[_vm._v(_vm._s(row.id))])]}},{key:"content",fn:function(ref){
var row = ref.row;
return [_c('vxe-grid',{attrs:{"columns":_vm.staffCard,"data":row.children,"auto-resize":""}})]}}])}),_c('vxe-table-column',{attrs:{"field":"name","title":"员工姓名","width":"150","show-overflow":""}}),_c('vxe-table-column',{attrs:{"field":"departName","title":"所属部门","min-width":"100","show-overflow":""}}),_c('vxe-table-column',{attrs:{"field":"projectName","title":"关联项目","min-width":"250","show-overflow":""}}),_c('vxe-table-column',{attrs:{"field":"phone","title":"手机号码","width":"150","show-overflow":""}}),_c('vxe-table-column',{attrs:{"field":"position","title":"员工职位","min-width":"100","show-overflow":""}}),_c('vxe-table-column',{attrs:{"field":"nickname","title":"员工昵称","min-width":"130","show-overflow":""}}),_c('vxe-table-column',{attrs:{"field":"avatar","title":"员工头像","width":"100","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('viewer',[_c('b-img',{staticClass:"d-inline-block",attrs:{"src":row.avatar,"height":"32","width":"auto"}})],1)]}}])}),_c('vxe-table-column',{attrs:{"field":"status","title":"状态","width":"120","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('b-badge',{staticClass:"px-1 py-25",attrs:{"pill":"","variant":row.status ? 'light-primary' : 'light-warning'}},[_vm._v(" "+_vm._s(row.status ? '启用' : '停用')+" ")])]}}])}),_c('vxe-table-column',{attrs:{"field":"action","title":"操作","align":"center","width":"190","fixed":"right"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('table-action-panel',{attrs:{"rowName":row.id,"extraData":row,"menu":_vm.setMenuDisabled(row, 'status')},on:{"click-event":_vm.tableActionEvent}})]}}])})],1),_c('page-set-panel',{attrs:{"page":_vm.searchParams.page,"size":_vm.searchParams.size,"total":_vm.total},on:{"update:page":function($event){return _vm.$set(_vm.searchParams, "page", $event)},"update:size":function($event){return _vm.$set(_vm.searchParams, "size", $event)}}})],1),_c('SidebarPanel',{attrs:{"blankEditParams":_vm.blankEditParams,"editValue":_vm.editValue,"title":"员工"},on:{"edit-event":_vm.editEvent},model:{value:(_vm.isShowSidebarActive),callback:function ($$v) {_vm.isShowSidebarActive=$$v},expression:"isShowSidebarActive"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }